import { Button } from '@/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  margin: 0 32px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const MessageWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 20px;

  & > p {
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const ButtonSubmit = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  min-height: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 16px 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: calc(50% - 1px);
    left: 0;
    background-color: #d5d5d5;
  }

  span {
    position: absolute;
    top: calc(50% - 10px);
    padding: 0 6px;
    background-color: #ffffff;
  }
`;
