import { Button } from '@/components';
import { ButtonProps } from '@/components/@types';
import SolidSVG, { IconArrowNext } from '@/libs/SolidSVG';
import { FC, memo, useEffect, useRef } from 'react';

export const NextButton: FC<ButtonProps> = memo((props) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const bodyWidth = document.body.clientWidth;
    if (ref.current) {
      ref.current.style.left = `${bodyWidth - 100}px`;
    }
  }, []);

  return (
    <Button ref={ref} {...props}>
      <SolidSVG path={IconArrowNext} width={12} height={12} />
    </Button>
  );
});
