/* eslint-disable no-empty */
import { sessionAPI } from '@/libs/api';
import { useMounted } from '@/libs/hooks';
import { authService } from '@/services/auth.service';
import { appStore } from '@/store';
import { isChannelMyGP, isUserAgentMobile, isUserAgentMyGP } from '@/utils/helpers';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';
import { useCallback, useEffect, useState } from 'react';
import { initialValues } from './validation';

export const useSignin = (headerToken?: string) => {
  const [isHydrating, setHydrating] = useState(true);
  const [isLoading, setLoading] = useState<'SIGN_IN' | 'GUEST' | 'NONE'>('NONE');
  const isMounted = useMounted();

  const router = useRouter();

  // Hydrate...
  // If user is recurring, collect ref from
  // cookie and claim the user session
  useEffect(() => {
    const hydrate = async () => {
      const params = new URL(window.location.href).searchParams;
      const channel = params.get('channel');
      const mevrik_ref = authService.get();
      let token = (params.get('token') as string) ?? headerToken;

      const isMobile = isUserAgentMobile(navigator.userAgent);
      let isMyGP = isUserAgentMyGP(navigator.userAgent);
      if (!isMyGP) {
        isMyGP = isChannelMyGP(channel || '');
      }

      appStore.setState((prev) => ({ ...prev, isMobile, isMyGP }));

      try {
        if (mevrik_ref && !token) {
          const { success, data } = await sessionAPI.iWasHere(mevrik_ref);
          if (!success || !data?.token) {
            throw new Error('Token not found');
          }

          token = data.token;
        }

        // Claim for the user session with the new token
        const { success, data } = await sessionAPI.getUserSession(token);
        if (!success || !data?.user?.unique_id) {
          throw new Error('Could not claim user session');
        }

        // remove token from url params
        // window.history.replaceState({}, document.title, window.location.pathname);
        authService.set(data.user.unique_id);

        const isChatHistory = !!data?.user.case_id;
        appStore.setState((prev) => ({
          ...prev,
          userSession: data,
          activeScreen: data?.mode === 'USER' || (mevrik_ref && isChatHistory) ? 'CONVERSATION' : 'USER_SIGNED_IN',
          token,
        }));
        router.replace('/');
      } catch (error) {
        destroyCookie(null, 'mevrik_ref');
        router.replace('/');
      } finally {
        if (isMounted()) {
          setHydrating(false);
        }
      }
    };

    hydrate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  // Event handler for signin
  // If user isn't recurring, request for a new user session
  // when user pass name and phone number and click continue
  const handleSignin = useCallback(
    async (value: typeof initialValues) => {
      let newToken: string | undefined;

      setLoading('SIGN_IN');

      try {
        if (!value.name || !value.phoneNumber) {
          throw new Error('Please enter your name and phone number');
        }

        // Create new token if user has no token
        const initChatRes = await sessionAPI.initChatForVideo(value.name, value.phoneNumber, 'robi-website-video');
        if (!initChatRes.success || !initChatRes.data?.token) {
          throw new Error('Could not generate new token');
        }

        newToken = initChatRes.data.token;

        if (!newToken) {
          throw new Error('Token not found');
        }

        // Claim for the user session with the new token
        const { success, data } = await sessionAPI.getUserSession(newToken);
        if (!success || !data?.user?.unique_id) {
          throw new Error('Could not claim user session');
        }

        authService.set(data.user.unique_id);

        appStore.setState((prev) => ({
          ...prev,
          userSession: data,
          activeScreen: data?.mode === 'USER' || data?.mode === 'ANON' ? 'CONVERSATION' : 'USER_SIGNED_IN',
          token: newToken,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        if (isMounted()) {
          setLoading('NONE');
        }
      }
    },
    [isMounted],
  );

  return { isHydrating, isLoading, handleSignin };
};
