import { FC, memo, SVGAttributes } from 'react';

export type SolidSVGProps = {
  path: string;
} & SVGAttributes<SVGElement>;

const SolidSVG: FC<SolidSVGProps> = (props) => {
  const { path, fill = '#000000', width = 24, height = 24, viewBox = '0 0 48 48', className, ...rest } = props;
  const svgProps = { width, height, viewBox, className };
  const pathProps = { d: path, fill, ...rest };

  return (
    <svg {...svgProps} xmlns="http://www.w3.org/2000/svg">
      <path {...pathProps} />
    </svg>
  );
};

export default memo(SolidSVG);
export * from './paths';
