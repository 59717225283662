import { Form } from '@/components';
import { FormTextareaProps } from '@/components/@types';
import { useScroll } from '@/libs/hooks';
import { CSSProperties, FC, memo, Ref, useEffect } from 'react';

export type TypoInputProps = {
  fwdRef?: Ref<HTMLInputElement>;
} & Omit<FormTextareaProps, 'style'>;

const DEFAULT_HEIGHT = 52;
const MAX_SCROLL_HEIGHT = 72;
const styles: CSSProperties = {
  height: `${DEFAULT_HEIGHT}px`,
  paddingRight: '89px',
  resize: 'none',
};

export const TypoInput: FC<TypoInputProps> = memo(({ placeholder = 'Send a message...', ...rest }) => {
  const { elementRef, scrollToBottom } = useScroll<HTMLTextAreaElement>();

  useEffect(() => {
    if (elementRef.current) {
      const prevSibling = elementRef.current.parentElement?.previousElementSibling as HTMLDivElement;
      const isScrollable = elementRef.current?.scrollHeight > elementRef.current?.clientHeight;
      if (isScrollable) {
        if (elementRef.current.scrollHeight > MAX_SCROLL_HEIGHT) {
          elementRef.current.style.height = `${MAX_SCROLL_HEIGHT}px`;
          prevSibling.style.height = `calc(100% - ${MAX_SCROLL_HEIGHT + 16}px)`;
        } else {
          elementRef.current.style.height = `${elementRef.current.scrollHeight}px`;
          prevSibling.style.height = `calc(100% - ${elementRef.current.scrollHeight + 16}px)`;
        }
      } else {
        elementRef.current.style.height = `${DEFAULT_HEIGHT}px`;
        prevSibling?.removeAttribute('style');
      }
      scrollToBottom();
    }
  }, [elementRef, rest.value, scrollToBottom]);

  return <Form.Textarea ref={elementRef} placeholder={placeholder} style={styles} {...rest} />;
});
