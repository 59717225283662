import { useCallback, useEffect, useRef, useState } from 'react';

const useCountdown = (value = 0) => {
  const [count, setCount] = useState(value);
  const interval = useRef<NodeJS.Timer | undefined>(undefined);

  const startInterval = useCallback(() => {
    if (interval.current) clearInterval(interval.current);
    interval.current = setInterval(() => setCount((prevState) => prevState - 1), 1000);
  }, []);

  const stopInterval = useCallback(() => {
    if (interval.current) clearInterval(interval.current);
    interval.current = undefined;
  }, []);

  useEffect(() => {
    startInterval();
    if (count === 0 || count < 0) stopInterval();

    return () => {
      stopInterval();
    };
  }, [count, startInterval, stopInterval]);

  const handleStartCount = useCallback((value: number) => setCount(value), []);

  const handleStopCount = useCallback(() => setCount(0), []);

  return {
    count,
    startCount: handleStartCount,
    stopCount: handleStopCount,
    resumeCount: startInterval,
    pauseCount: stopInterval,
  };
};

export default useCountdown;
