import { useCallback, useEffect, useRef } from 'react';

type Element = HTMLDivElement | HTMLTextAreaElement;

const useScroll = <T extends Element>() => {
  const elementRef = useRef<T>(null);

  const scrollToBottom = useCallback(() => {
    if (elementRef.current) {
      elementRef.current.scrollTop = elementRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [elementRef?.current?.scrollHeight, scrollToBottom]);

  return {
    elementRef,
    scrollToBottom,
  };
};

export default useScroll;
