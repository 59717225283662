import { Button, PoweredBy } from '@/components';
import { chatAPI } from '@/libs/api';
import { chatStore, useChatStore } from '@/store';
import { AGENT_AVATAR, CUSTOMER_AVATAR } from '@/utils/constants';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AtchItems, ButtonAddon, ButtonGroup, ButtonSend, InputWrapper, TypoInput } from './ChatActions';
import { Message } from './Message';
import { VideoChat } from './VideoChat';
import { VideoChatModal } from './VideoChatModal';
import { ChatWrapper, ConversationWrapper } from './styles';
import { useConversation } from './useConversation';

const Conversation: FC = () => {
  const {
    isLoading,
    inputValue,
    fileURL,
    setFileURL,
    scrollerRef,
    handleChange,
    handleFileChange,
    handleKeyPress,
    handleSend,
    handleQuickReply,
    handleGenericTemplate,
  } = useConversation();

  const { messages, videoRoom, isVideoChat, lastMsgID } = useChatStore();
  const [isRetry, setRetry] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const handleCall = useCallback(async () => {
    try {
      const { success, data } = await chatAPI.getVideoRoom();
      if (!success || !data?.data) {
        throw new Error('Something went wrong');
      }

      setRetry(false);
      chatStore.setVideoRoom(data.data);
    } catch (error) {
      setRetry(true);
    }
  }, []);

  useEffect(() => {
    if (isRetry && isVideoChat === 'REQUESTED') {
      timeoutRef.current = setTimeout(() => {
        handleCall();
      }, 10000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [handleCall, isRetry, isVideoChat]);

  useEffect(() => {
    if (isVideoChat === 'REQUESTED') {
      handleCall();
    }
  }, [handleCall, isVideoChat]);

  const isModalOpen = useMemo(() => {
    if (isVideoChat === 'REQUESTED' && videoRoom) {
      return true;
    }

    return false;
  }, [isVideoChat, videoRoom]);

  const handleAcceptVideoChat = useCallback(() => {
    if (videoRoom?.visitorurl) {
      window.open(videoRoom.visitorurl, '_blank');
      chatStore.setVideoChatStatus('IDLE');
    }
  }, [videoRoom?.visitorurl]);

  if (isVideoChat === 'ACCEPTED' && videoRoom) {
    return <VideoChat />;
  }

  return (
    <ConversationWrapper
      style={{
        position: 'relative',
        height: 'calc(100vh - 70px)',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflow: 'hidden',
      }}
    >
      <ChatWrapper ref={scrollerRef}>
        <Message.Time time={new Date()} />
        <Message.General>
          This conversation will remain available for up to 24 hrs after the chat is closed. To remove it sooner, choose
          End Chat from the drop-down menu.
        </Message.General>

        {messages.map(
          ({
            message: {
              metadata: { id, customer, attending, is_reply },
              text,
              image,
              attachment,
              generic,
              buttons,
              quick_replies,
            },
          }) => {
            const isCustomer = is_reply === 0;
            const avatarURL = isCustomer
              ? customer?.profile_pic || CUSTOMER_AVATAR
              : attending?.profile_pic || AGENT_AVATAR;
            const name = isCustomer ? customer?.name : attending?.name;
            const attachmentURL = attachment?.payload?.url;
            const attachmentType = attachment?.type;
            const isGeneric = generic?.length;
            const isQuickReply = quick_replies?.length;
            const isLastItem = id === lastMsgID;

            return (
              <Message.Wrapper key={id} align={isCustomer ? 'right' : 'left'}>
                <Message.Avatar src={avatarURL} alt={name} style={{ order: isCustomer ? '100' : '-1' }} />
                <Message.Content>
                  {!isCustomer && name && <Message.By by={name} />}

                  {((attachmentURL && attachmentType === 'image') || image) && (
                    <Message.Image src={attachment?.payload.url || image} alt="chat" />
                  )}

                  {!buttons && text && (
                    <Message.Text
                      variant={isCustomer ? 'sender' : 'receiver'}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  )}

                  {isGeneric && (
                    <Message.Sliders>
                      {generic.map(({ title, subtitle, image_url, buttons }, i) => (
                        <Message.Text key={i} className="generic" variant={isCustomer ? 'sender' : 'receiver'}>
                          {image_url && <Message.Image src={image_url} alt={title || 'Chat'} />}

                          {title && <Message.Heading>{title}</Message.Heading>}

                          {subtitle && <Message.SubHeading dangerouslySetInnerHTML={{ __html: subtitle }} />}

                          {buttons?.map(({ title, ...rest }, j) => (
                            <Message.Button
                              key={j}
                              type="button"
                              variant="white"
                              block
                              onClick={() => handleGenericTemplate({ title, ...rest })}
                            >
                              {title}
                            </Message.Button>
                          ))}
                        </Message.Text>
                      ))}
                    </Message.Sliders>
                  )}

                  {buttons && (
                    <Message.Text className="generic" variant={isCustomer ? 'sender' : 'receiver'}>
                      {(buttons?.text || text) && (
                        <Message.SubHeading dangerouslySetInnerHTML={{ __html: `${buttons?.text || text}` }} />
                      )}

                      {buttons?.buttons?.map(({ title, ...rest }, i) => (
                        <Message.Button
                          key={i}
                          type="button"
                          variant="white"
                          block
                          onClick={() => handleGenericTemplate({ ...rest, title })}
                        >
                          {title}
                        </Message.Button>
                      ))}
                    </Message.Text>
                  )}

                  {isQuickReply && isLastItem && (
                    <Message.ButtonGroup inline>
                      {quick_replies.map(({ title, payload }, i) => (
                        <Button
                          key={i}
                          type="button"
                          rounded="pill"
                          variant="black"
                          onClick={() => handleQuickReply(payload, title)}
                        >
                          {title}
                        </Button>
                      ))}
                    </Message.ButtonGroup>
                  )}
                </Message.Content>
              </Message.Wrapper>
            );
          },
        )}
      </ChatWrapper>

      {isLoading === 'SEND' && (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}

      <InputWrapper className={fileURL ? 'active file' : inputValue ? 'active' : ''}>
        <TypoInput value={inputValue} onChange={handleChange} onKeyPress={handleKeyPress} />
        <AtchItems isLoading={isLoading === 'FILE'} atchPreviewURL={fileURL} onDelete={() => setFileURL('')} />
        {/* <ButtonGroup>
          <ButtonAddon onChange={handleFileChange} />
          {isLoading === 'NONE' && (inputValue || fileURL) ? (
            <ButtonSend onClick={() => handleSend()} />
          ) : (
            <ButtonMenu
              onMenuItemClick={(item) => {
                const { payload, title } = item;
                handleQuickReply(payload, title, 'postback');
              }}
            />
          )}
        </ButtonGroup> */}
        <ButtonGroup>
          <ButtonAddon onChange={handleFileChange} />
          {isLoading === 'NONE' && (inputValue || fileURL) && <ButtonSend onClick={() => handleSend()} />}
        </ButtonGroup>
      </InputWrapper>

      <VideoChatModal
        isOpen={isModalOpen}
        onConfirm={handleAcceptVideoChat}
        onClose={() => chatStore.setVideoChatStatus('DECLINED')}
      />
      <PoweredBy style={{ margin: '8px 16px', justifyContent: 'end' }} />
    </ConversationWrapper>
  );
};

export default memo(Conversation);
