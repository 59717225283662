export const initialValues = {
  name: '',
  emailAddress: '',
  phoneNumber: '',
  subject: '',
  message: '',
};

export const initialErrors = {
  name: '',
  emailAddress: '',
  phoneNumber: '',
  subject: '',
  message: '',
};

export const validateForm = (values: Partial<typeof initialValues>): Partial<typeof initialErrors> => {
  const errors: Partial<typeof initialErrors> = {};
  const phoneRegExp = /^(\+88)?01[3-9]{1}[0-9]{8}$/;

  if (values && Object.keys(values).length > 0) {
    for (const key of Object.keys(values) as (keyof typeof initialValues)[]) {
      if (key in initialErrors) {
        switch (key) {
          case 'name':
            errors.name = !values.name ? 'Name is required' : '';
            break;
          // case 'emailAddress':
          //   if (!values.emailAddress) {
          //     errors.emailAddress = 'Email address is required';
          //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
          //     errors.emailAddress = 'Email address is invalid';
          //   } else {
          //     errors.emailAddress = '';
          //   }
          //   break;
          case 'phoneNumber':
            if (!values.phoneNumber) {
              errors.phoneNumber = 'Phone number is required';
            } else if (!phoneRegExp.test(values.phoneNumber)) {
              errors.phoneNumber = 'Phone number is invalid';
            } else {
              errors.phoneNumber = '';
            }
            break;
          // case 'subject':
          //   errors.subject = !values.subject ? 'Subject is required' : '';
          //   break;
          // case 'message':
          //   errors.message = !values.message ? 'Message is required' : '';
          //   break;
          default:
            break;
        }
      }
    }
  }

  return errors;
};
