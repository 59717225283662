/* eslint-disable no-empty */
import config from '@/config';
import { chatAPI } from '@/libs/api';
import { useMounted, useScroll } from '@/libs/hooks';
import { chatStore, useAppStore, useChatStore } from '@/store';
import { isEnterKey } from '@/utils/helpers';
import { useRouter } from 'next/router';
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

type IsLoader = 'SEND' | 'FILE' | 'NONE';

type MessageType = 'quick_reply' | 'postback' | 'video_call_request';

import * as Ably from 'ably';

const client = new Ably.Realtime({ key: config.pusherKey + ':' + config.pusherSecret });
client.connection.on('connected', () => {
  console.log('ably connected');
});

export const useConversation = () => {
  const [inputValue, setInputValue] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [isLoading, setLoading] = useState<IsLoader>('NONE');
  const router = useRouter();
  const unsubPostRef = useRef<() => void>();
  const unsubReplyRef = useRef<() => void>();
  // const unsubEndRef = useRef<() => void>();
  const { elementRef, scrollToBottom } = useScroll<HTMLDivElement>();
  const isMounted = useMounted();

  const { lastMsgID, messages } = useChatStore();
  const {
    userSession: {
      user: { name, channel, session_id },
    },
  } = useAppStore();

  // Play incoming audo
  const playAudio = () => {
    const audio = new Audio('/assets/audio/incoming.mp3');
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    audio.play().catch(() => {});
  };

  // Get messages based on last message id
  const getOldMessages = useCallback(
    async (type?: 'POST' | 'REPLY') => {
      setLoading('SEND');

      try {
        const res = await chatAPI.getOldMessages({
          from_message_id: lastMsgID,
        });

        if (res.success && res.data.data) {
          chatStore.setMessages(res.data.data);
          scrollToBottom();
          if (type === 'REPLY') {
            playAudio();
          }
          return true;
        }

        return false;
      } catch (error) {
        return false;
      } finally {
        if (isMounted()) {
          setLoading('NONE');
        }
      }
    },
    [isMounted, lastMsgID, scrollToBottom],
  );

  // Implement pusher service
  useEffect(() => {
    // const pusherService = new PusherService(config.broadcastDriver, config.pusherKey);

    // if (channel) {
    //   // user channel name
    //   // pusherService.subscribe(`messages-user-${mevrik_id}`);
    //   pusherService.subscribe(`session${session_id}`);
    //   unsubPostRef.current = pusherService.on(`message_post`, () => getOldMessages('POST'));
    //   unsubReplyRef.current = pusherService.on(`message_reply`, () => getOldMessages('REPLY'));
    //   // unsubRef.current = pusherService.on(`message_clsoed`, () => getOldMessages());
    //   // unsubEndRef.current = pusherService.on(`case_closed`, () => {
    //   //   pusherService.unsubscribe(channel);
    //   //   endConversation();
    //   // });

    //   unsubReplyRef.current = pusherService.on('video_call_start', () => chatStore.setVideoChatStatus('REQUESTED'));
    // }
    const _channel = client.channels.get(`session${session_id}`);
    _channel.subscribe((message) => {
      console.log('ably message', message);
      if (message.name === 'message_post') {
        getOldMessages('POST');
      }
      if (message.name === 'message_reply') {
        getOldMessages('REPLY');
      }
      if (message.name === 'video_call_start') {
        chatStore.setVideoChatStatus('REQUESTED');
      }
      if (message.name === 'video_call_close') {
        router.push('https://www.robi.com.bd/en');
      }
    });

    return () => {
      // pusherService.unsubscribe(channel);
      // unsubPostRef.current?.();
      // unsubReplyRef.current?.();
      // unsubEndRef.current?.();
      _channel.unsubscribe();
    };
  }, [channel, getOldMessages, session_id]);

  // Send message
  const handleSend = useCallback(
    async (messageVal?: string) => {
      const message = messageVal?.trim() || inputValue.trim();
      if (!message && !fileURL) return;

      const payload: IAPI.ImagePayload['message'] = {
        type: 'video_call_request',
      };

      if (message) {
        payload.body = message;
      }

      if (fileURL) {
        payload.attachment = {
          type: 'image',
          payload: {
            url: fileURL,
          },
        };
      }

      setLoading('SEND');
      setInputValue('');
      setFileURL('');

      try {
        const res = await chatAPI.sendMessage({
          message: payload,
        });

        if (res.success && !lastMsgID) {
          chatStore.setLastMsgID(res.data.message.metadata.id);
        }
      } catch (error) {
      } finally {
        if (isMounted()) {
          setLoading('NONE');
        }
      }
    },
    [fileURL, inputValue, isMounted, lastMsgID],
  );

  // Initiate session conversation
  const initSessionConversation = useCallback(async () => {
    setLoading('SEND');

    try {
      const isHistory = await getOldMessages();
      if (!isHistory) {
        await handleSend('Hello');
      }
    } catch (error) {
    } finally {
      if (isMounted()) {
        setLoading('NONE');
      }
    }
  }, [getOldMessages, handleSend, isMounted]);

  useEffect(() => {
    if (!messages.length) {
      initSessionConversation();
    }
  }, [initSessionConversation, messages]);

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  }, []);

  // Send message on enter
  const handleKeyPress = useCallback(
    async (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isEnterKey(e)) {
        e.preventDefault();
        await handleSend();
      }
    },
    [handleSend],
  );

  // Update file
  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) return;

      const file = e.target.files[0];
      if (!file) return;
      setFileURL(URL.createObjectURL(file));

      setLoading('FILE');
      try {
        const res = await chatAPI.uploadFile(file);
        if (res.success && isMounted()) {
          setFileURL(res.data.url);
          e.target.value = '';
        }
      } catch (error) {
      } finally {
        if (isMounted()) {
          setLoading('NONE');
        }
      }
    },
    [isMounted],
  );

  // Send message on Quick Reply & Postback
  const handleQuickReply = useCallback(
    async (payload: string, title: string, type: MessageType = 'quick_reply') => {
      const params: IAPI.QRPayload = {
        action: 'message_post',
        session_id: session_id,
        message: {
          message_type: type,
          body: title,
        },
      };

      if (type === 'quick_reply') {
        params.message.quick_reply = {
          payload,
        };
      }

      if (type === 'postback') {
        params.message.postback = {
          payload,
        };
      }

      if (!params.message.quick_reply && !params.message.postback) return;

      setLoading('SEND');

      try {
        await chatAPI.sendWithQR(params);
      } catch (error) {
      } finally {
        if (isMounted()) {
          setLoading('NONE');
        }
      }
    },
    [isMounted],
  );

  // Send message for Generic Template
  const handleGenericTemplate = useCallback(
    async ({ title, type, url, payload }: IAPI.GenericButton) => {
      if (!type) return;

      switch (type) {
        case 'web_url':
          if (!url) return;
          window.open(url, '_blank');
          break;

        case 'phone_number':
          if (!payload) return;
          window.open(`tel:${payload}`);
          break;

        case 'postback':
          if (!title || !payload) return;
          await handleQuickReply(payload, title, 'postback');
          break;

        default:
          break;
      }
    },
    [handleQuickReply],
  );

  return {
    isLoading,
    inputValue,
    fileURL,
    setFileURL,
    scrollerRef: elementRef,
    handleSend,
    handleChange,
    handleKeyPress,
    handleFileChange,
    handleQuickReply,
    handleGenericTemplate,
  };
};
