import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  margin: 32px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const MessageWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 20px;

  & > p {
    max-width: 256px;
    margin: 0 auto;
  }
`;

export const TimeWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding: 0;
    margin-left: 10px;
  }
`;
