/* eslint-disable no-empty */
import config from '@/config';
import { HttpService } from '@/services';
import {
  ClaimSessionParams,
  InitChatParams,
  InitChatResponse,
  OldMessageResponse,
  UserSessionResponse,
  VerifiedClientResponse,
  WidgetPersonaResponse,
} from './@types';

class SessionAPI {
  constructor(private http: HttpService) {}

  getVerifyReferrer(referrer: string) {
    return this.http.get<VerifiedClientResponse>(`referrer_verification?utm_source=${encodeURIComponent(referrer)}`);
  }

  getWidgetPersona() {
    return this.http.get<WidgetPersonaResponse>('widget-persona');
  }

  getUserSession(token: string) {
    const localApi = config.localApiURL;
    return this.http.post<UserSessionResponse>(
      `${localApi}/api/v1/session-data`,
      {},
      {
        headers: {
          'x-mevrik-token': token,
        },
      },
    );
  }

  ping(token: string) {
    const localApi = config.localApiURL;
    return this.http.post(
      `${localApi}/api/v1/ping`,
      {},
      {
        headers: {
          'x-mevrik-token': token,
        },
      },
    );
  }

  claimSession(payload: ClaimSessionParams, token: string) {
    return this.http.post<UserSessionResponse>(
      'claim-session',
      {
        data: payload,
      },
      {
        headers: {
          'x-mevrik-token': token,
        },
      },
    );
  }

  initChatByReferrer(referrer: string, channel: string) {
    const localApi = config.localApiURL;
    return this.http.get<InitChatResponse>(
      `${localApi}/init-by-trusted-origin?channel=${channel}&utm_source=${encodeURIComponent(referrer)}`,
    );
  }

  iWasHere(mevrikVisitRef: string) {
    const localApi = config.localApiURL;
    return this.http.get<InitChatResponse>(`${localApi}/i-was-here?visit_ref=${mevrikVisitRef}`);
  }

  initChat({ secrentId, secrentToken, deviceId, msisdn, name }: InitChatParams) {
    return this.http.post<InitChatResponse>(
      'init-chat',
      {},
      {
        headers: {
          'x-mevrik-client-secret-id': secrentId,
          'x-mevrik-client-secret-token': secrentToken,
          'x-mevrik-device-id': deviceId,
          'x-mevrik-msisdn': msisdn,
          'x-mevrik-name': name,
        },
      },
    );
  }

  initChatByTrustedOrigin(channel: string, utm_source: string) {
    return this.http.post<InitChatResponse>(
      '/init-chat',
      {
        channel,
        utm_source,
      },
      {
        headers: {
          'x-mevrik-client-secret-id': '382548',
          'x-mevrik-client-secret-token': '$2y$10$HUFFyZWIvLvYEvkOQT/QZez0N0fEPdhDiByvJ5QlXquAVPCODznDu',
        },
      },
    );
  }

  initChatForVideo(name: string, msisdn: string, channel = 'gp-website-video', session_path = 'live/default') {
    return this.http.post<InitChatResponse>(
      `/init-chat`,
      {
        channel,
        session_path,
      },
      {
        headers: {
          'x-mevrik-client-secret-id': '382548',
          'x-mevrik-client-secret-token': '$2y$10$HUFFyZWIvLvYEvkOQT/QZez0N0fEPdhDiByvJ5QlXquAVPCODznDu',
          'x-mevrik-msisdn': msisdn,
          'x-mevrik-name': name,
        },
      },
    );
  }

  checkChatHistory(token: string) {
    return this.http.post<OldMessageResponse>(
      'messages?action=message_history',
      {
        history: {
          from_message_id: 0,
          limit: 200,
        },
        message: {
          mevrik_id: null,
        },
      },
      {
        headers: {
          'x-mevrik-token': token,
        },
      },
    );
  }

  endChat(token: string) {
    return this.http.post(
      'reset',
      {},
      {
        headers: {
          'x-mevrik-token': token,
        },
      },
    );
  }
}

const http = new HttpService(config.apiURL);
export const sessionAPI = new SessionAPI(http);
