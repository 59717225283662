import { FC, memo } from 'react';
import { FormItemProps, FormTextareaProps } from '../@types';
import { FormGroup, FormInput, FormLabel, FormMessage, FormTextarea } from './styles';

export const FormItem: FC<FormItemProps> = memo(
  ({
    inputProps: { type, ...restInputProps } = { type: 'text' },
    labelProps: { labelText, ...restLabelProps } = {
      labelText: '',
    },
    variant,
    message,
    ...rest
  }) => {
    return (
      <FormGroup isUIStyleLabel={!!restInputProps?.value} {...rest}>
        <FormLabel {...restLabelProps}>{labelText}</FormLabel>
        {type === 'textarea' ? (
          <FormTextarea rows={3} {...(restInputProps as FormTextareaProps)} variant={variant} />
        ) : (
          <FormInput type={type} {...restInputProps} variant={variant} />
        )}
        {message && <FormMessage variant={variant}>{message}</FormMessage>}
      </FormGroup>
    );
  },
);
