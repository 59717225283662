import { Card } from '@/components';
import { FC, memo, useCallback, useMemo } from 'react';
import { MessageTimeProps } from './types';

export const MessageTime: FC<MessageTimeProps> = memo(({ time = new Date() }) => {
  const getTime = useMemo(() => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const timeString = `${hours}:${minutes}`;
    return timeString;
  }, [time]);

  const convertTo12Hour = useCallback((value: string) => {
    const regex = /am|pm/i;
    if (regex.test(value)) {
      return value;
    }

    const [hours, minutes] = value.split(':');
    const isAm = Number(hours) < 12;
    const convertedHours = isAm ? hours : Number(hours) - 12;
    const convertedMinutes = minutes.padStart(2, '0');
    const convertedTime = `${convertedHours}:${convertedMinutes} ${isAm ? 'AM' : 'PM'}`;
    return convertedTime;
  }, []);

  return <Card.Fluid style={{ marginBottom: '6px' }}>{convertTo12Hour(getTime)}</Card.Fluid>;
});
