import { Button, Modal } from '@/components';
import { FC, memo } from 'react';

type VideoChatModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

export const VideoChatModal: FC<VideoChatModalProps> = memo(({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Heading>Would you like to start vidoe chat?</Modal.Heading>
    <Modal.Text>
      Your conversation with Robi will be removed and they&rsquo;ll no longer be able to send you messages.
    </Modal.Text>
    <Modal.ButtonGroup>
      <Button block type="button" size="sm" variant="light" onClick={onClose}>
        No
      </Button>
      <Button block type="button" size="sm" variant="primary" onClick={onConfirm}>
        Yes
      </Button>
    </Modal.ButtonGroup>
  </Modal>
));
