import { isBackspaceKey } from '@/utils/helpers';
import { ChangeEvent, FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { FormOTPProps } from '../@types';
import { FormInput, FormMessage, FormOTPWrapper } from './styles';

export const FormOTP: FC<FormOTPProps> = memo((props) => {
  const { length = 4, name, value, onOTPChange, onComplete, variant, message, ...rest } = props;
  const [inputs, setInputs] = useState(Array(length).fill(''));
  const inputGroupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      const valueArr = value.split('');
      if (valueArr.length === length) {
        setInputs(valueArr);
      }
    }
  }, [length, value]);

  useEffect(() => {
    if (inputs.every((input) => input !== '')) {
      onComplete?.({
        name: name || 'OTPCode',
        value: inputs.join(''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, name]);

  const handleChange = useCallback(
    (index, e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value?.length > 1) {
        return;
      }

      const newInputs = [...inputs];
      newInputs[index] = value || '';
      setInputs(newInputs);
      onOTPChange?.({
        name: name || 'OTPCode',
        value: newInputs.join(''),
      });
    },
    [inputs, name, onOTPChange],
  );

  useEffect(() => {
    if (inputGroupRef.current) {
      const inputGroup = inputGroupRef.current;
      const inputElements = inputGroup.querySelectorAll('input');

      const focusIndex = inputs.findIndex((input) => input === '') || 0;
      if (focusIndex !== -1) {
        inputElements[focusIndex].focus();
      }
    }
  }, [inputs]);

  const handlePress = useCallback((index, e) => {
    if (isBackspaceKey(e) && inputGroupRef.current) {
      const inputGroup = inputGroupRef.current;
      const inputElements = inputGroup.querySelectorAll('input');

      const focusIndex = index - 1 || 0;
      if (focusIndex !== -1) {
        inputElements[focusIndex].focus();
      }
    }
  }, []);

  return (
    <FormOTPWrapper {...rest}>
      <div ref={inputGroupRef} className="InputGroup">
        {Array.from({ length }).map((_, index) => (
          <FormInput
            key={index}
            type="number"
            variant={variant}
            value={inputs[index]}
            onChange={(e) => handleChange(index, e)}
            onKeyUp={(e) => handlePress(index, e)}
          />
        ))}
      </div>
      {message && <FormMessage variant={variant}>{message}</FormMessage>}
    </FormOTPWrapper>
  );
});
