import { Button } from '@/components';
import SolidSVG, { IconCheckCircle, IconLoader, IconTrash } from '@/libs/SolidSVG';
import { FC, useMemo } from 'react';
import { AtchListWrapper } from './styles';

export type AtchItemsProps = {
  isLoading: boolean;
  atchPreviewURL: string;
  onDelete?: () => void;
};

export const AtchItems: FC<AtchItemsProps> = ({ isLoading, atchPreviewURL, onDelete }) => {
  const atchPreviewName = useMemo(() => {
    return atchPreviewURL.split('/').pop();
  }, [atchPreviewURL]);

  if (!atchPreviewURL) return null;

  return (
    <AtchListWrapper>
      <ul>
        <li>
          <span className="statusBar">
            {isLoading ? (
              <SolidSVG className="loader" path={IconLoader} width={16} height={16} fill="#F6001D" />
            ) : (
              <SolidSVG path={IconCheckCircle} width={16} height={16} fill="#F6001D" />
            )}
          </span>
          <img src={atchPreviewURL} alt="atch" />
          <span>{atchPreviewName}</span>
          <Button onClick={onDelete}>
            <SolidSVG path={IconTrash} width={18} height={18} fill="#F24822" />
          </Button>
        </li>
      </ul>
    </AtchListWrapper>
  );
};
