import { Button } from '@/components';
import SolidSVG, { IconAttachment } from '@/libs/SolidSVG';
import { FC, memo, useCallback, useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import { AtchInput, AtchInputProps } from './AtchInput';

export type ButtonAddonProps = Omit<AtchInputProps, 'fwdRef'>;

export const ButtonAddon: FC<ButtonAddonProps> = memo((props) => {
  const atchInputRef = useRef<HTMLInputElement>(null);
  const {
    colors: { text },
  } = useContext(ThemeContext);

  const handleAddonClick = useCallback(() => {
    if (atchInputRef.current) {
      atchInputRef.current.click();
    }
  }, []);

  return (
    <>
      <AtchInput fwdRef={atchInputRef} {...props} />
      <Button type="button" variant="link" rounded="circle" onClick={handleAddonClick}>
        <SolidSVG path={IconAttachment} fill={text} width={24} height={24} />
      </Button>
    </>
  );
});
