import { Header } from '@/components';
import { UserSignin, ValidateOTP } from '@/containers';
import { useAppStore } from '@/store';
import type { NextPage, NextPageContext } from 'next';
import dynamic from 'next/dynamic';
import { FC, memo } from 'react';

const Conversation = dynamic(() => import('@/containers/Conversation'), { ssr: false });
const AblyProvider = dynamic(() => import('@/containers/AblyProvider'), { ssr: false });

const WidgetScreen: FC<{ headerToken?: string }> = memo(({ headerToken }) => {
  const { activeScreen } = useAppStore();

  switch (activeScreen) {
    case 'USER_SIGNED_IN':
      return <UserSignin headerToken={headerToken} />;
    case 'OTP_VERIFICATION':
      return <ValidateOTP />;
    case 'CONVERSATION':
      return (
        <AblyProvider>
          <Conversation />
        </AblyProvider>
      );
    default:
      return <UserSignin />;
  }
});

const Home: NextPage<{ headerToken?: string | string[] | undefined }> = (props) => {
  const {
    userSession: {
      persona: { name, logo },
    },
    isSessionActive,
  } = useAppStore();

  return (
    <div className="WidgetContainer">
      <Header isActive={isSessionActive} name={name} logo={logo} />
      <WidgetScreen headerToken={props.headerToken?.toString()} />
    </div>
  );
};

export default Home;

Home.getInitialProps = async (ctx: NextPageContext): Promise<{ headerToken?: string | string[] | undefined }> => {
  const { req } = ctx;
  const token = req?.headers['x-mevrik-token'];

  if (token) {
    return { headerToken: token.toString() };
  }

  return { headerToken: undefined };
};
