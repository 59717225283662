import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex: none;

  & > button {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 2020;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 5px 0 34px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #fff;
    }

    &:first-child {
      left: -20px;
    }
  }
`;

export const Container = styled.div`
  min-width: 100%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SliderItems = styled.div`
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.05, 0, 0, 1);
  will-change: transform;
  white-space: nowrap;
  display: flex;

  & > div {
    margin-left: 12px;
    margin-right: 12px;
    min-width: 236px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
