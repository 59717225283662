import { FC, HTMLAttributes, memo } from 'react';
import styled from 'styled-components';

export const PoweredBy: FC<HTMLAttributes<HTMLDivElement>> = memo((props) => (
  <Wrapper {...props}>
    <p>
      powered by
      <a
        title="Mevrik.com"
        rel="referrer noreferrer"
        target="_blank"
        href="https://mevrik.com/?utm_source=messenger&utm_campaign=persistent_menu&utm_term=robi-axiata"
      >
        <img src="/mevrik-icon-14.svg" alt="Mevrik" />
      </a>
    </p>
    {/* <img
      src="/mevrik.svg"
      alt="Mevrik"
      onClick={() => {
        window.open(
          'https://mevrik.com/?utm_source=messenger&utm_campaign=persistent_menu&utm_term=grameenphone',
          '_blank',
        );
      }}
    /> */}
  </Wrapper>
));

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    margin-bottom: 0;
    color: #b2b2b2;
  }

  p a {
    display: inline-block;
    margin-bottom: 0;
    color: #b2b2b2;
    font-size: bold;
    text-decoration: none;
    padding-left: 3px;
  }

  img {
    margin-left: 5px;
    cursor: pointer;
  }
`;
