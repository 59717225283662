import { useOnClickOutside } from '@/libs/hooks';
import { FC, useRef } from 'react';
import { ModalProps, ModalType } from '../@types';
import { ModalButtonGroup, ModalHeading, ModalText, Wrapper } from './styles';

export const Modal: FC<ModalProps> & ModalType = ({ children, isOpen, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => onClose?.());

  return (
    <Wrapper isActive={isOpen}>
      <div className="Dialog" ref={ref}>
        {children}
      </div>
    </Wrapper>
  );
};

Modal.Heading = ModalHeading;
Modal.Text = ModalText;
Modal.ButtonGroup = ModalButtonGroup;
