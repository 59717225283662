import { FormItem } from './FormItem';
import { FormItemCheck } from './FormItemCheck';
import { FormItemWithButton } from './FormItemWithButton';
import { FormOTP } from './FormOTP';
import { FormCheck, FormGroup, FormInput, FormLabel, FormMessage, FormTextarea } from './styles';

export const Form = {
  Group: FormGroup,
  Label: FormLabel,
  Input: FormInput,
  Check: FormCheck,
  Textarea: FormTextarea,
  OTP: FormOTP,
  Message: FormMessage,
  Item: FormItem,
  ItemCheck: FormItemCheck,
  ItemWithButton: FormItemWithButton,
};
