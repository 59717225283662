import { hexToRGB } from '@/utils/helpers';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 24px;
  background: transparent;
`;

export const HeaderOverly = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
`;

export const PersonaAvatar = styled.div<{ isActive?: boolean }>`
  z-index: 1;
  position: relative;

  & > div {
    padding: 8px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.active {
    &::before {
      content: '';
      width: 15px;
      height: 15px;
      background-color: ${({ theme }) => theme.colors.success};
      border: 3px solid ${({ theme }) => theme.colors.bg};
      border-radius: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
`;

export const PersonaName = styled.div`
  width: calc(100% - 50px - 24px);
  margin-left: 16px;
  z-index: 99;

  h4,
  p {
    margin: 0;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const MenuItems = styled.nav`
  position: absolute;
  top: 100%;
  right: -10px;
  width: 228px;
  display: block;
  margin: 0;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: -3px 21px 100px rgba(0, 0, 0, 0.09);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-29px);
  transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s ease;
  z-index: 999;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      list-style: none;

      button {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        padding: 6px;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};

        &:focus {
          outline: none;
          background-color: ${({ theme }) => theme.colors.white};
        }

        svg {
          margin-right: 6px;
        }
      }

      a {
        padding: 6px;
        font-size: 15px;
        display: block;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  hr {
    width: 100%;
    height: 0;
    padding: 0;
    margin: 8px 0;
    border: 0;
    border-bottom: 1px solid ${({ theme }) => hexToRGB(theme.colors.bg, 0.5)};
  }
`;
