import { Avatar, Card } from '@/components';
import { FC, memo } from 'react';
import { MessageTime } from './MessageTime';
import { Sliders } from './Sliders';
import {
  MessageButton,
  MessageButtonGroup,
  MessageByWrapper,
  MessageContent,
  MessageHeading,
  MessageImage,
  MessageSubHeading,
  MessageText,
  MessageWrapper,
} from './styles';
import { MessageAvatarProps, MessageByProps, MessageGeneralProps } from './types';

export const MessageGeneral: FC<MessageGeneralProps> = memo(({ children }) => <Card.Fluid>{children}</Card.Fluid>);

export const MessageBy: FC<MessageByProps> = memo(({ by, text }) => (
  <MessageByWrapper>
    {by} {text ? <span>{text}</span> : ''}
  </MessageByWrapper>
));

export const MessageAvatar: FC<MessageAvatarProps> = memo(
  ({
    size = 'sm' as MessageAvatarProps['size'],
    src = '/assets/images/avatar-24.png',
    alt = 'avatar',
    style,
    ...rest
  }) => (
    <Avatar size={size} style={style}>
      <img src={src} alt={alt} {...rest} />
    </Avatar>
  ),
);

export const Message = {
  Wrapper: MessageWrapper,
  Content: MessageContent,
  Time: MessageTime,
  General: MessageGeneral,
  By: MessageBy,
  Avatar: MessageAvatar,
  Image: MessageImage,
  Text: MessageText,
  Heading: MessageHeading,
  SubHeading: MessageSubHeading,
  ButtonGroup: MessageButtonGroup,
  Button: MessageButton,
  Sliders,
};
