import { useCallback, useEffect, useRef } from 'react';

const useMounted = () => {
  const isMountedRef = useRef(true);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  const isMounted = useCallback(() => isMountedRef.current, []);

  return isMounted;
};

export default useMounted;
