import { useAppStore } from '@/store';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NextButton } from './NextButton';
import { PrevButton } from './PrevButton';
import { Container, SliderItems, Wrapper } from './styles';

export const Sliders: FC = memo(({ children }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [slideItems, setSlideItems] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderItemsRef = useRef<HTMLDivElement>(null);

  const { isMobile, isMyGP } = useAppStore();
  const isHandheld = useMemo(() => isMobile || isMyGP, [isMobile, isMyGP]);

  useEffect(() => {
    if (sliderItemsRef.current?.children?.length) {
      setActiveItem(1);
      setSlideItems(sliderItemsRef.current.children.length);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current && isHandheld) {
      containerRef.current.style.width = `${window.innerWidth - 42}px`;
    }
  }, [isHandheld]);

  const handlePrev = useCallback(() => {
    if (activeItem > 1) {
      setCurrentPosition(currentPosition + 260);
      setActiveItem(activeItem - 1);
    }
  }, [activeItem, currentPosition]);

  const handleNext = useCallback(() => {
    if (activeItem < slideItems) {
      setCurrentPosition(currentPosition - 260);
      setActiveItem(activeItem + 1);
    }
  }, [activeItem, currentPosition, slideItems]);

  return (
    <Wrapper>
      <PrevButton style={{ display: activeItem > 1 && !isHandheld ? 'block' : 'none' }} onClick={handlePrev} />
      <Container ref={containerRef}>
        <SliderItems ref={sliderItemsRef} style={{ transform: `translateX(${currentPosition}px)` }}>
          {children}
        </SliderItems>
      </Container>
      <NextButton style={{ display: activeItem < slideItems && !isHandheld ? 'block' : 'none' }} onClick={handleNext} />
    </Wrapper>
  );
});
