import { Button } from '@/components';
import { ButtonProps } from '@/components/@types';
import SolidSVG, { IconArrowPrev } from '@/libs/SolidSVG';
import { FC, memo } from 'react';

export const PrevButton: FC<ButtonProps> = memo((props) => (
  <Button {...props}>
    <SolidSVG path={IconArrowPrev} width={12} height={12} />
  </Button>
));
