/* eslint-disable no-empty */
import { sessionAPI } from '@/libs/api';
import { useCountdown, useMounted } from '@/libs/hooks';
import { authService } from '@/services/auth.service';
import { appStore, useAppStore } from '@/store';
import { useCallback, useState } from 'react';
import { initialValues } from './validation';

const OTP_DURATION = 180;

export const useValidateOTP = () => {
  const { count, startCount } = useCountdown(OTP_DURATION);
  const [OTPCount, setOTPCount] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const isMounted = useMounted();

  const {
    token,
    userSession: {
      user: { name, user_ref },
    },
  } = useAppStore();

  // Reset OTP
  const handleResendOTP = useCallback(async () => {
    const newOTPCount = OTPCount + 1;

    setLoading(true);

    try {
      // Claim for the new OTP
      const { success } = await sessionAPI.claimSession(
        {
          user_ref,
          name,
          otp_resend: OTPCount,
        },
        token as string,
      );
      if (!success) {
        throw new Error('Something went wrong while resending OTP');
      }

      if (isMounted()) {
        setOTPCount(newOTPCount);
        startCount(OTP_DURATION);
      }
    } catch (error) {
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  }, [OTPCount, isMounted, name, startCount, token, user_ref]);

  // Validate OTP
  // and claim for the user session
  const handleValidateOTP = useCallback(
    async (values: typeof initialValues) => {
      setLoading(true);

      try {
        if (values?.OTPCode?.length !== 4) {
          throw new Error('Your OTP is not correct');
        }

        // Claim for the user session with the new OTP
        const { success, data } = await sessionAPI.claimSession(
          {
            user_ref,
            name,
            otp_verify: values.OTPCode,
          },
          token as string,
        );
        if (!success || !data?.user?.unique_id) {
          throw new Error('Your OTP is not correct');
        }

        authService.set(data.user.unique_id);

        appStore.setState((prev) => ({
          ...prev,
          userSession: data,
          activeScreen: data?.mode === 'USER' ? 'CONVERSATION' : 'USER_SIGNED_IN',
        }));
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message);
        }

        throw new Error('Something went wrong while validating OTP');
      } finally {
        if (isMounted()) {
          setLoading(false);
        }
      }
    },
    [isMounted, name, token, user_ref],
  );

  return { count, isLoading, handleResendOTP, handleValidateOTP };
};
