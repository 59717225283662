import { Store, useStore } from '@/libs/store';
import { ChatStore } from './types';

const initialState: ChatStore = {
  messages: [],
  isVideoChat: 'IDLE',
};

class ChatStoreClass extends Store<ChatStore> {
  constructor(state: ChatStore) {
    super(state);
  }

  private getUniqueMessages(messages: IAPI.OldMessageResponse['data']) {
    const mappedMessages = new Map(messages.map((m) => [m.message.metadata.id, m]));
    return [...mappedMessages.values()];
  }

  setMessages(newMessages: IAPI.OldMessageResponse['data']) {
    this.setState((v) => {
      const messages = v.messages.concat(newMessages);
      const unique = this.getUniqueMessages(messages);

      // const isVideoChat = unique.some(
      //   (m) => m.message.metadata.channel.type === 'sign_line' && m.message.text === 'Call Start',
      // )
      //   ? 'REQUESTED'
      //   : 'IDLE';

      return {
        ...v,
        messages: unique,
        firstMsgID: unique[0].message.metadata.id,
        lastMsgID: unique[unique.length - 1].message.metadata.id,
        // isVideoChat,
      };
    });
  }

  addMessage(message: IAPI.OldMessageResponse['data'][0]) {
    this.setState((v) => {
      const messages = [...v.messages, message];
      const unique = this.getUniqueMessages(messages);

      return {
        ...v,
        messages: unique,
        firstMsgID: unique[0].message.metadata.id,
        lastMsgID: unique[unique.length - 1].message.metadata.id,
      };
    });
  }

  setLastMsgID(id: number) {
    return this.setState((v) => ({ ...v, lastMsgID: id }));
  }

  setVideoRoom(videoRoom: IAPI.VideoRoom['data']) {
    return this.setState((v) => ({ ...v, videoRoom }));
  }

  setVideoChatStatus(status: 'IDLE' | 'REQUESTED' | 'ACCEPTED' | 'DECLINED' | 'ENDED') {
    return this.setState((v) => ({ ...v, isVideoChat: status }));
  }

  clearChat() {
    this.setState((v) => {
      return {
        ...v,
        messages: [],
        firstMsgID: undefined,
        lastMsgID: undefined,
        videoRoom: undefined,
        isCallRequest: false,
      };
    });
  }
}

export const chatStore = new ChatStoreClass(initialState);
export const useChatStore = () => useStore(chatStore);
