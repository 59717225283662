import { FC, memo } from 'react';
import { Modal } from '../Modal';
import { Button } from '../button';

type EndChatModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

export const EndChatModal: FC<EndChatModalProps> = memo(({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Heading>Are you sure you&rsquo;d like to End chat?</Modal.Heading>
    <Modal.Text>
      Your conversation with Robi will be removed and they&rsquo;ll no longer be able to send you messages.
    </Modal.Text>
    <Modal.ButtonGroup>
      <Button block type="button" size="sm" variant="light" onClick={onClose}>
        Cancel
      </Button>
      <Button block type="button" size="sm" variant="danger" onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.ButtonGroup>
  </Modal>
));
