import { StoreListner, StoreSetState, StoreState } from './types';

class Store<T extends StoreState> {
  protected listners = new Set<StoreListner<T>>();

  constructor(protected state: T) {}

  getState() {
    return this.state;
  }

  setState(newState: StoreSetState<T>) {
    this.state = typeof newState === 'function' ? newState(this.state) : newState;
    this.listners.forEach((listner) => listner(this.state));
  }

  listen(listner: StoreListner<T>) {
    this.listners.add(listner);
    return () => this.listners.delete(listner);
  }
}

export default Store;
