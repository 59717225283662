export const initialValues = {
  OTPCode: '',
};

export const initialErrors = {
  OTPCode: '',
};

export const validateForm = (values: Partial<typeof initialValues>): Partial<typeof initialErrors> => {
  const errors: Partial<typeof initialErrors> = {};

  if (values && Object.keys(values).length > 0) {
    for (const key of Object.keys(values) as (keyof typeof initialValues)[]) {
      if (key in initialValues) {
        errors[key] = !values[key] ? `OTP code is required` : '';
      }
    }
  }

  return errors;
};
