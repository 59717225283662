import { useAppStore } from '@/store';
import { FC, memo } from 'react';
import { MessageWrapper } from './styles';

export const Message: FC = memo(() => {
  const { userSession } = useAppStore();

  return (
    <MessageWrapper>
      <h4>OTP Verification</h4>
      <p>
        We have sent a OTP code via SMS to
        <br />
        {userSession?.user?.user_ref}
      </p>
    </MessageWrapper>
  );
});
