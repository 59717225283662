import { useEffect, useState } from 'react';
import Store from './store';
import { StoreState } from './types';

const useStore = <T extends StoreState>(store: Store<T>) => {
  const [state, setState] = useState(store.getState());

  useEffect(() => {
    const unsubscribe = store.listen(setState);

    return () => {
      unsubscribe();
    };
  }, [store]);

  return state;
};

export default useStore;
