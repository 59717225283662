import { Store, useStore } from '@/libs/store';
import { BRAND_URL } from '@/utils/constants';
import { validURL } from '@/utils/helpers';
import { AppStore } from './types';

export const initialUserSession: AppStore['userSession'] = {
  mode: 'ANON',
  user: {
    name: 'Guest',
    channel: 'mevrik-chat',
    session_id: 1,
    mevrik_id: 1,
    case_id: 0,
    user_ref: 'Guest',
    device_ref: '620e6c902f6a3',
    broadcast: '',
    unique_id: '',
    agent: 'bot',
  },
  persona: {
    logo: BRAND_URL,
    name: 'Robi Axiata Ltd.',
    color: '#F6001D',
    greetings_title: 'Hello there 👋',
    greetings_descriptions: 'Please fill in the form below before starting the chat.',
    button_name: 'Get Started',
    get_started_button_color: '#F6001D',
    customer_chat_color: '#F6001D',
    header_title_bg_color: 'glass',
  },
};

const initialState: AppStore = {
  activeScreen: 'USER_SIGNED_IN',
  userSession: initialUserSession,
  token: undefined,
  // secretID: undefined,
  // secretToken: undefined,
  isSessionActive: false,
  isMobile: false,
  isMyGP: false,
};

class AppStoreClass extends Store<AppStore> {
  constructor(state: AppStore) {
    super(state);
  }

  setUser(name: string, userRef: string) {
    this.setState((v) => ({
      ...v,
      userSession: {
        ...v.userSession,
        user: {
          ...v.userSession.user,
          name,
          user_ref: userRef,
        },
      },
    }));
  }

  setActiveScreen(screen: AppStore['activeScreen']) {
    this.setState((v) => ({ ...v, activeScreen: screen }));
  }

  setUserSession({ mode, persona: { logo, ...restPersona }, user }: AppStore['userSession']) {
    this.setState((v) => ({
      ...v,
      userSession: {
        mode,
        persona: {
          logo: validURL(logo) ? logo : BRAND_URL,
          ...restPersona,
        },
        user,
      },
    }));
  }

  setToken(token: string) {
    this.setState((v) => ({ ...v, token }));
  }

  setClientID(clientId: string) {
    this.setState((v) => ({ ...v, clientId }));
  }

  setClientSecret(clientSecret: string) {
    this.setState((v) => ({ ...v, clientSecret }));
  }

  setSecrets(clientId: string, clientSecret: string) {
    this.setState((v) => ({
      ...v,
      clientId,
      clientSecret,
    }));
  }

  setSessionActive(isSessionActive: boolean) {
    this.setState((v) => ({ ...v, isSessionActive }));
  }

  setIsMobile(isMobile: boolean) {
    this.setState((v) => ({ ...v, isMobile }));
  }

  setIsMyGP(isMyGP: boolean) {
    this.setState((v) => ({ ...v, isMyGP }));
  }

  reset() {
    this.setState(initialState);
  }
}

export const appStore = new AppStoreClass(initialState);
export const useAppStore = () => useStore(appStore);
