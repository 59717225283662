import { useChatStore } from '@/store';
import { useMemo } from 'react';
import { ConversationWrapper } from './styles';

export const VideoChat = () => {
  const { videoRoom } = useChatStore();
  const videoRoomURL = useMemo(() => {
    if (videoRoom?.visitorurl) {
      return videoRoom.visitorurl;
    }

    return null;
  }, [videoRoom]);

  if (!videoRoomURL) {
    return null;
  }

  return (
    <ConversationWrapper>
      <iframe
        src={videoRoomURL}
        allow="microphone; camera; fullscreen; screen-share"
        title="video-chat"
        width="100%"
        height={320}
        style={{
          border: 'none',
        }}
      />
    </ConversationWrapper>
  );
};
