import { Button } from '@/components';
import SolidSVG, { IconClose } from '@/libs/SolidSVG';

export const CloseButton = () => {
  const handleClose = () => {
    parent.postMessage('close_widget', '*');
  };

  return (
    <Button
      type="button"
      variant="link"
      style={{
        padding: '5px 9px',
        marginLeft: '10px',
        zIndex: 1,
        backgroundColor: '#FFE1E1',
      }}
      onClick={handleClose}
    >
      <SolidSVG width={12} height={12} path={IconClose} fill="#FF0000" />
    </Button>
  );
};
