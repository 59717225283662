import { useAppStore } from '@/store';
import { FC, Fragment, memo } from 'react';
import { Avatar } from '../avatar';
import { CloseButton } from './CloseButton';
import { DropdownMenu } from './DropdownMenu';
import { HeaderOverly, HeaderWrapper, PersonaAvatar, PersonaName } from './styles';

export type HeaderProps = {
  name: string;
  logo: string;
  isActive?: boolean;
};

export const Header: FC<HeaderProps> = memo(({ logo, name, isActive }) => {
  const { isMobile, isMyGP } = useAppStore();

  return (
    <Fragment>
      <HeaderOverly style={{ zIndex: 1 }} />
      <HeaderWrapper style={{ zIndex: 1, maxWidth: '550px', marginLeft: 'auto', marginRight: 'auto' }}>
        <PersonaAvatar className={isActive ? 'active' : ''}>
          <Avatar size="lg">
            <img src={logo} alt={name} />
          </Avatar>
        </PersonaAvatar>
        <PersonaName>
          <h4>{name}</h4>
          <p>Replies Instantly</p>
        </PersonaName>
        <DropdownMenu />
        {!isMyGP && isMobile && <CloseButton />}
      </HeaderWrapper>
    </Fragment>
  );
});
