import { Form, PoweredBy } from '@/components';
import { useForm } from '@/libs/hooks';
import { memo } from 'react';
import { ButtonSubmit, Container } from './styles';
import { useSignin } from './useSignin';
import { initialErrors, initialValues, validateForm } from './validation';

const UserSignedIn = ({ headerToken }: { headerToken?: string }) => {
  const { isHydrating, isLoading, handleSignin } = useSignin(headerToken);

  const { values, errors, handleSubmit, handleChange } = useForm({
    initialValues,
    initialErrors,
    validate: validateForm,
    onSuccess: handleSignin,
  });

  if (isHydrating) return <div>Loading...</div>;

  return (
    <Container style={{ marginTop: 30, maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
      <p style={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}>
        Dear Customer, to access the Robi Video Chat service, please visit{' '}
        <a href="https://www.robi.com.bd/en">https://www.robi.com.bd/en</a> between 10 AM and 7 PM.
      </p>
      <form style={{ display: 'none' }} onSubmit={handleSubmit}>
        <Form.Item
          uiStyle="mui"
          labelProps={{ labelText: 'Full name' }}
          inputProps={{
            type: 'text',
            name: 'name',
            placeholder: 'Enter your phone number',
            value: values.name,
            onChange: handleChange,
          }}
          variant={errors.name ? 'danger' : undefined}
          message={errors.name}
        />
        {/* <Form.Item
          uiStyle="mui"
          labelProps={{ labelText: 'Email address' }}
          inputProps={{
            type: 'email',
            name: 'emailAddress',
            placeholder: 'Enter your phone number',
            value: values.emailAddress,
            onChange: handleChange,
          }}
          variant={errors.emailAddress ? 'danger' : undefined}
          message={errors.emailAddress}
        /> */}
        <Form.Item
          uiStyle="mui"
          labelProps={{ labelText: 'Phone number' }}
          inputProps={{
            type: 'tel',
            placeholder: 'Enter your phone number',
            name: 'phoneNumber',
            maxLength: 11,
            value: values.phoneNumber,
            onChange: handleChange,
          }}
          variant={errors.phoneNumber ? 'danger' : undefined}
          message={errors.phoneNumber}
        />
        {/* <Form.Item
          uiStyle="mui"
          labelProps={{ labelText: 'Subject' }}
          inputProps={{
            type: 'text',
            name: 'subject',
            placeholder: 'Write subject here',
            value: values.subject,
            onChange: handleChange,
          }}
          variant={errors.subject ? 'danger' : undefined}
          message={errors.subject}
        />
        <Form.Item
          uiStyle="mui"
          labelProps={{ labelText: 'Message' }}
          inputProps={{
            type: 'textarea',
            name: 'message',
            placeholder: 'Write message here',
            value: values.message,
            onChange: handleChange,
          }}
          variant={errors.message ? 'danger' : undefined}
          message={errors.message}
        /> */}
        <ButtonSubmit type="submit" size="lg" block disabled={isLoading === 'SIGN_IN'}>
          Send
        </ButtonSubmit>
      </form>
      <PoweredBy style={{ margin: '40px 0 25px 0' }} />
    </Container>
  );
};

export default memo(UserSignedIn);
