import { Button } from '@/components';
import { ButtonProps } from '@/components/@types';
import SolidSVG, { IconSendPlane } from '@/libs/SolidSVG';
import { FC, memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';

export type ButtonSendProps = Omit<ButtonProps, 'type' | 'variant' | 'circle' | 'className'> & {
  isLoading?: boolean;
};

export const ButtonSend: FC<ButtonSendProps> = memo(({ isLoading, ...rest }) => {
  const {
    colors: { white },
  } = useContext(ThemeContext);

  return (
    <Button type="button" rounded="circle" disabled={isLoading} {...rest}>
      <SolidSVG path={IconSendPlane} fill={white} width={12} height={12} />
    </Button>
  );
});
