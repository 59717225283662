import styled, { css } from 'styled-components';
import {
  FormCheckProps,
  FormGroupProps,
  FormInputProps,
  FormLabelProps,
  FormMessageProps,
  FormOTPProps,
  FormTextareaProps,
} from '../@types';

export const FormLabel = styled.label<FormLabelProps>`
  margin-bottom: 8px;
  display: inline-block;

  ${({ srOnly }) => {
    if (srOnly) {
      return css`
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      `;
    }
    return null;
  }}
`;

export const FormInput = styled.input<FormInputProps>`
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 16px;
  background-color: #f9f9f9;
  border: 1px solid ${({ theme, variant }) => (variant ? theme.colors[variant] : '#d5d5d5')};
  appearance: none;
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:focus {
    border-color: ${({ theme, variant }) => (variant ? theme.colors[variant] : '#121f3e')};
  }

  &:disabled {
    background-color: #e9ecef;
  }
`;

export const FormTextarea = styled.textarea<FormTextareaProps>`
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 16px;
  background-color: #f9f9f9;
  border: 1px solid ${({ theme, variant }) => (variant ? theme.colors[variant] : '#d5d5d5')};
  appearance: none;
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: none;
  outline: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:focus {
    border-color: ${({ theme, variant }) => (variant ? theme.colors[variant] : '#121f3e')};
  }
`;

export const FormCheck = styled.input<FormCheckProps>`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  appearance: none;
  border: 1px solid ${({ theme, variant }) => (variant ? theme.colors[variant] : '#b2b2b2')};
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '4px')};
  box-shadow: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};

    &[type='checkbox'] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
`;

export const FormMessage = styled.p<FormMessageProps>`
  margin: 4px 0 0 0;
  color: ${({ theme, variant = 'danger' }) => theme.colors[variant]};
`;

export const ItemWithButtonWrapper = styled.div`
  position: relative;

  & > button {
    position: absolute;
    right: 4px;
    top: 4px;
  }
`;

export const FormGroup = styled.div<FormGroupProps>`
  display: block;
  width: 100%;
  margin-bottom: 16px;

  // MUI styles
  ${({ theme, uiStyle, isUIStyleLabel }) =>
    uiStyle &&
    css`
      position: relative;
      z-index: 1;

      ${FormLabel} {
        position: absolute;
        left: 0;
        top: ${isUIStyleLabel ? '-9px' : 0};
        padding: ${isUIStyleLabel ? '0 8px' : '16px'};
        opacity: ${isUIStyleLabel ? 1 : 0.85};
        z-index: ${isUIStyleLabel ? 1 : -1};
        margin: ${isUIStyleLabel ? '0 8px' : 0};
        transition: all 0.2s ease-in-out;

        ${isUIStyleLabel &&
        css`
          font-size: 14px;
          background-color: ${theme.colors.white};
        `}
      }

      ${FormInput} {
        background-color: transparent;

        &::-webkit-input-placeholder {
          color: transparent;
        }

        &:-moz-placeholder {
          color: transparent; /* Firefox 18- */
        }

        &::-moz-placeholder {
          color: transparent; /* Firefox 19+ */
        }

        &:-ms-input-placeholder {
          color: transparent;
        }
      }
    `}
`;

export const FormOTPWrapper = styled(FormGroup)<FormOTPProps>`
  .InputGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  ${FormInput} {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding: 10.5px 12px;
    -moz-appearance: textfield;
    background-color: transparent;
    border-radius: 0;
    border-width: 0 0 1px 0;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;
