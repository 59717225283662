/* eslint-disable jsx-a11y/alt-text */
import { FC, memo } from 'react';
import { CardChatProps, CardSenderProps } from '../@types';
import { CardReceiverWrapper, CardSenderWrapper } from './styles';

export const CardSender: FC<CardSenderProps> = memo(({ fontSize = '14', children, ...rest }) => (
  <CardSenderWrapper variant="primary" colorVariant="white" fontSize={fontSize} {...rest}>
    {children}
  </CardSenderWrapper>
));

export const CardReceiver: FC<CardSenderProps> = memo(({ fontSize = '14', children, ...rest }) => (
  <CardReceiverWrapper variant="light" fontSize={fontSize} {...rest}>
    {children}
  </CardReceiverWrapper>
));

export const CardChat: FC<CardChatProps> = memo(({ variant, ...rest }) => {
  if (variant === 'sender') {
    return <CardSender {...rest} />;
  }

  return <CardReceiver {...rest} />;
});
