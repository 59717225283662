import styled, { css } from 'styled-components';
import { AvatarProps } from './@types';

export const Avatar = styled.div<AvatarProps>`
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return css`
          width: 18px;
          height: 18px;
          line-height: 18px;
        `;
      case 'md':
        return css`
          width: 24px;
          height: 24px;
          line-height: 24px;
        `;
      case 'lg':
        return css`
          width: 50px;
          height: 50px;
          line-height: 50px;
        `;
      default:
        return css`
          width: 62px;
          height: 62px;
          line-height: 62px;
        `;
    }
  }}
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;

  img {
    width: 100%;
  }
`;
