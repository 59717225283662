import { Button, Form } from '@/components';
import { useForm } from '@/libs/hooks';
import { FC, memo } from 'react';
import { ButtonSubmit } from '../UserSignin/styles';
import { Message } from './Message';
import { Container, TimeWrapper } from './styles';
import { useValidateOTP } from './useValidateOTP';
import { initialErrors, initialValues, validateForm } from './validation';

const ValidateOTP: FC = () => {
  const { count, handleResendOTP, handleValidateOTP } = useValidateOTP();

  const { values, errors, setErrors, handleSubmit, handleOTPChange } = useForm({
    initialValues,
    initialErrors,
    validate: validateForm,
    onSuccess: async (values) => {
      try {
        await handleValidateOTP(values);
      } catch (error) {
        setErrors({
          OTPCode: error instanceof Error ? error.message : 'Your OTP is not correct',
        });
      }
    },
  });

  return (
    <Container>
      <Message />
      <form onSubmit={handleSubmit}>
        <Form.OTP
          name="OTPCode"
          value={values.OTPCode}
          onOTPChange={(data) => handleOTPChange(data)}
          variant={errors.OTPCode ? 'danger' : undefined}
          message={errors.OTPCode}
          style={{ marginBottom: '2rem' }}
        />
        <TimeWrapper>
          <span>{count > 0 ? `Time Remaining ${count}s` : "Don't have the OTP?"}</span>
          <Button type="button" variant="link" onClick={handleResendOTP} disabled={count > 0}>
            Resend
          </Button>
        </TimeWrapper>
        <ButtonSubmit type="submit" size="lg" block>
          Verify OTP & Proceed
        </ButtonSubmit>
      </form>
    </Container>
  );
};

export default memo(ValidateOTP);
