import { Button, Card } from '@/components';
import styled, { css } from 'styled-components';
import { MessageWrapperProps } from './types';

export const MessageByWrapper = styled(Card.Text)`
  font-size: 13px;
  padding: 0;
  margin-bottom: 6px;

  & > span {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const MessageImage = styled.img`
  width: 80%;
  margin: 0 0 16px 0;
  border-radius: 23px 23px 23px 6px;
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const MessageButtonGroup = styled.div<{ inline?: boolean }>`
  margin-top: 8px;
  margin-bottom: 16px;

  ${({ inline }) => {
    if (inline) {
      return css`
        display: block;
        text-align: center;
      `;
    }

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
    `;
  }}

  button {
    margin-bottom: ${({ inline }) => (inline ? '8px' : '0')};

    & + button {
      margin-left: 8px;
    }
  }
`;

export const MessageHeading = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const MessageSubHeading = styled.p`
  margin-bottom: 0;
`;

export const MessageButton = styled(Button)`
  margin-top: 12px;
  font-weight: 600;
`;

export const MessageText = styled(Card.Chat)`
  &.generic {
    white-space: normal;

    ${MessageImage} {
      width: calc(100% + 32px);
      margin: -16px -16px 10px -16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export const MessageWrapper = styled.div<MessageWrapperProps>`
  display: grid;
  grid-template-columns: ${({ align }) => (align === 'right' ? '1fr auto' : 'auto 1fr')};
  grid-gap: 8px;
  align-items: flex-end;
  margin-bottom: 16px;

  ${({ align }) => css`
    ${MessageText} {
      justify-content: ${align === 'right' ? 'flex-end' : 'flex-start'};
    }

    ${MessageContent} {
      justify-content: ${align === 'right' ? 'flex-end' : 'flex-start'};
    }

    ${MessageImage} {
      align-self: ${align === 'right' ? 'flex-end' : 'flex-start'};
      border-radius: 23px 23px 6px 23px;
    }
  `}
`;
