import styled from 'styled-components';
import { CardTextProps } from '../@types';

export const CardText = styled.div<CardTextProps>`
  width: 100%;
  display: block;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 10px;
  color: ${({ theme, colorVariant }) => theme.colors[colorVariant || 'text']};
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  background-color: ${({ theme, variant }) =>
    variant === 'primary' ? theme.colors.primary : variant === 'light' ? '#e3e6ea' : 'transparent'};
`;

export const CardSenderWrapper = styled(CardText)`
  border-radius: 23px 23px 6px 23px;
  width: fit-content;
  max-width: 80%;
  margin-left: auto;
  word-wrap: break-word;
`;

export const CardReceiverWrapper = styled(CardSenderWrapper)`
  border-radius: 23px 23px 23px 6px;
  margin-left: 0;
`;
