/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseResponse, HttpOptions, IRequestInit } from './types';

export class HttpService {
  constructor(private baseURL: string, private config?: HttpOptions) {}

  get<T>(url: string, options?: IRequestInit) {
    return this.request<T>('GET', url, options);
  }

  post<T>(url: string, body: unknown, options?: IRequestInit) {
    return this.request<T>('POST', url, { ...options, body: JSON.stringify(body) });
  }

  put<T>(url: string, body: unknown, options?: IRequestInit) {
    return this.request<T>('PUT', url, { ...options, body: JSON.stringify(body) });
  }

  delete<T>(url: string, options?: IRequestInit) {
    return this.request<T>('DELETE', url, options);
  }

  patch<T>(url: string, body: unknown, options?: IRequestInit) {
    return this.request<T>('PATCH', url, { ...options, body: JSON.stringify(body) });
  }

  upload<T>(url: string, body: FormData, options?: IRequestInit) {
    return this.request<T>('POST', url, { ...options, body });
  }

  // url with / (slash will be appened to abse url)
  // url without / (slah) will have a prefix /api/v1
  private async request<T>(
    method: string,
    url: string,
    { headers, ...restOptions } = { headers: {} } as RequestInit,
  ): Promise<BaseResponse<T>> {
    let requestURL = url;

    if (url.indexOf('http') !== 0) {
      if (url.indexOf('/') === 0) {
        requestURL = `${this.baseURL}${url}`;
      } else {
        requestURL = `${this.baseURL}/api/v1/${url}`;
      }
    }

    console.log('[api] ' + requestURL);

    const token = this.config?.getToken?.() || '';
    const headerOptions: Record<string, any> = {
      // 'Content-Type': 'application/json',
      'Content-Type': 'text/plain',
      Accept: 'application/json',
      // 'x-mevrik-token': this.config?.getToken?.() || '',
      ...headers,
    };
    if (token) headerOptions['x-mevrik-token'] = token;

    if (restOptions.body?.constructor?.name === 'FormData') {
      delete headerOptions['Content-Type'];
    }

    try {
      const response = await fetch(requestURL, {
        method,
        headers: headerOptions,
        ...restOptions,
      });

      const data = await response.json();
      return {
        success: response.ok,
        data,
      };
    } catch (error) {
      throw new Error('Something went wrong! ' + error);
    }
  }
}
