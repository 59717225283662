import { parseCookies, setCookie } from 'nookies';

class AuthService {
  private name = 'mevrikVisitRef';

  set(value: string) {
    setCookie(null, this.name, value, {
      maxAge: 15 * 60,
      path: '/',
      sameSite: 'None',
      secure: true,
    });
  }

  get() {
    const cookie = parseCookies();
    return cookie[this.name];
  }

  remove() {
    this.set('');
    // destroyCookie({}, this.name, { path: '/' });
  }
}

export const authService = new AuthService();
