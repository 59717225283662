import { KeyboardEvent } from 'react';

export const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return alpha !== undefined ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const isNullProperties = (obj: Record<string, unknown>): boolean => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== '') return false;
  }
  return true;
};

export const formatReadable = (value: string): string => value.replace(/([A-Z])/g, ' $1');

export const formatValidatorKey = (value: string): string => {
  const val = formatReadable(value);
  return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
};

export const isValidHex = (themeColor: string) => /^#[0-9A-F]{6}$/i.test(themeColor);

export const getTime = (date: Date) => {
  const format = (val: number) => (val < 10 ? `0${val}` : val);

  const hr = format(date.getHours());
  const min = format(date.getMinutes());
  const sec = format(date.getSeconds());
  const milliSec = date.getMilliseconds();
  return `${hr}:${min}:${sec}.${milliSec}`;
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const validURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return pattern.test(str);
};

export const isUserAgentMobile = (userAgent: string) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|MyGP|Opera Mini/i.test(userAgent);

export const isUserAgentMyGP = (userAgent: string) => /MyGP|node-fetch/i.test(userAgent);

export const isChannelMyGP = (channel: string) => /gp-android|gp-ios|gp-mobile/i.test(channel);

export const isEnterKey = (e: KeyboardEvent<HTMLTextAreaElement>) => e.key === 'Enter';

export const isBackspaceKey = (e: KeyboardEvent<HTMLTextAreaElement>) => e.key === 'Backspace';

export const isValidTime = (time: string) => {
  const [hour, minute] = time.split(':');
  const hourNumber = parseInt(hour, 10);
  const minuteNumber = parseInt(minute, 10);
  return hourNumber >= 10 && hourNumber <= 18 && minuteNumber >= 0 && minuteNumber <= 59;
};
