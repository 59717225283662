import { FC, memo, useEffect, useRef } from 'react';
import { FormItemWithButtonProps } from '../@types';
import { Button } from '../button';
import { FormGroup, FormInput, FormLabel, FormMessage, ItemWithButtonWrapper } from './styles';

export const FormItemWithButton: FC<FormItemWithButtonProps> = memo(
  ({
    inputProps,
    labelProps: { labelText, ...restLabelProps } = {
      labelText: '',
    },
    buttonProps: { buttonText, ...restButtonProps } = {
      buttonText: '',
    },
    variant,
    message,
    ...rest
  }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (buttonRef.current && inputRef.current) {
        const buttonWidth = buttonRef.current.offsetWidth;
        const inputRightPadding = parseInt(getComputedStyle(inputRef.current).paddingRight, 10);
        inputRef.current.style.paddingRight = `${buttonWidth + inputRightPadding}px`;
      }
    }, [buttonRef, inputRef]);

    return (
      <FormGroup isUIStyleLabel={!!inputProps?.value} {...rest}>
        <FormLabel {...restLabelProps}>{labelText}</FormLabel>
        <ItemWithButtonWrapper>
          <FormInput ref={inputRef} {...inputProps} variant={variant} />
          <Button ref={buttonRef} {...restButtonProps}>
            {buttonText}
          </Button>
        </ItemWithButtonWrapper>
        {message && <FormMessage variant={variant}>{message}</FormMessage>}
      </FormGroup>
    );
  },
);
