import { FC, memo } from 'react';
import { CardFluidProps } from '../@types';
import { CardText } from './styles';

export const CardFluid: FC<CardFluidProps> = memo(
  ({ colorVariant = 'secondary', fontSize = '13', textAlign = 'center', children, style, ...rest }) => (
    <CardText
      fontSize={fontSize}
      textAlign={textAlign}
      colorVariant={colorVariant}
      {...rest}
      style={{
        padding: '0',
        ...style,
      }}
    >
      {children}
    </CardText>
  ),
);
