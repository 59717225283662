import { CardChat, CardReceiver, CardSender } from './CardChat';
import { CardFluid } from './CardFluid';
import { CardText } from './styles';

export const Card = {
  Text: CardText,
  Fluid: CardFluid,
  Chat: CardChat,
  Sender: CardSender,
  Receiver: CardReceiver,
};
