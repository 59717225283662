import styled, { keyframes } from 'styled-components';

const Loader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const AtchListWrapper = styled.div`
  width: 100%;
  height: 157px;
  position: absolute;
  bottom: calc(100% - 1px);
  z-index: 99;
  padding: 8px 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.white};

  ul {
    height: 100%;
    margin: 0 8px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;

    li {
      list-style: none;
      position: relative;

      img {
        width: 100%;
        height: 115px;
        object-fit: cover;
        border-radius: 5px;
      }

      button,
      .statusBar {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 26px;
        height: 26px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: #ffe1e1;
        border-color: #ffe1e1;
      }

      .statusBar {
        right: inherit;
        left: 10px;
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.white};

        .loader {
          animation: ${Loader} 2s infinite;
        }
      }
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin: 0 16px;
  position: sticky;
  bottom: 0;

  & > input {
    border-radius: 11px;
    padding: 18px 16px;
  }

  &.active {
    & > textarea,
    ${AtchListWrapper} {
      border-color: #121f3e;
    }
  }

  &.file {
    & > textarea {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  right: 16px;
  top: 0;

  & > button {
    width: 34px;
    height: 34px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & + button {
      margin-left: 5px;
    }
  }
`;
