import styled from 'styled-components';

export const ConversationWrapper = styled.div`
  display: block;
  width: 100%;
  height: calc(100vh - 70px - 20px);
  background-color: ${({ theme }) => theme.colors.white};

  & ~ div {
    margin-top: 0;
    margin-bottom: -10px;
  }
`;

export const ChatWrapper = styled.div`
  display: block;
  width: 100%;
  height: calc(100% - 90px);
  padding: 16px;
  overflow: hidden;
  overflow-y: auto;
`;
