/* eslint-disable no-empty */
import SolidSVG, { IconClose, IconMore } from '@/libs/SolidSVG';
import { sessionAPI } from '@/libs/api';
import { useOnClickOutside } from '@/libs/hooks';
import { authService } from '@/services/auth.service';
import { appStore, chatStore, useAppStore } from '@/store';
import { useRouter } from 'next/router';
import { Fragment, memo, useCallback, useRef, useState } from 'react';
import { Button } from '../button';
import { EndChatModal } from './EndChatModal';
import { DropdownWrapper, MenuItems } from './styles';

export const DropdownMenu = memo(() => {
  const router = useRouter();
  const { isMyGP, activeScreen, token } = useAppStore();

  // Manage dropdown menu
  const [isDropdownMenu, setDropdownMenu] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setDropdownMenu(false));
  const toggleDropdown = useCallback(() => setDropdownMenu((prev) => !prev), []);

  // Manage end chat modal
  const [isEndChatModal, setEndChatModal] = useState<boolean>(false);
  const handleEndChatModalOpen = useCallback(() => {
    setEndChatModal(true);
    setDropdownMenu(false);
  }, []);
  const handleEndChatModalClose = useCallback(() => setEndChatModal(false), []);

  /**
   * @todo set widget mode to closed and set active screen to UserSignIn for next reveal
   * @todo please destroyCookie - it seems to be not woring
   */
  const handleEndChatConfirm = useCallback(async () => {
    authService.remove();
    setEndChatModal(false);
    appStore.reset();
    chatStore.clearChat();
    parent.postMessage('close_widget', '*');

    try {
      await sessionAPI.endChat(token!);
    } catch (error) {
    } finally {
      router.reload();
    }
  }, [router, token]);

  return (
    <Fragment>
      <DropdownWrapper style={{ zIndex: 2 }} ref={dropdownRef}>
        <Button type="button" variant="link" className="menu-btn" style={{ padding: 0 }} onClick={toggleDropdown}>
          <SolidSVG path={IconMore} />
        </Button>

        <MenuItems className={isDropdownMenu ? 'active' : ''}>
          <ul>
            {!isMyGP && activeScreen === 'CONVERSATION' && (
              <Fragment>
                <li>
                  <Button type="button" variant="link" onClick={handleEndChatModalOpen}>
                    <SolidSVG path={IconClose} width={12} height={12} /> End Chat
                  </Button>
                </li>
                <hr />
              </Fragment>
            )}
            <li>
              <Button
                type="button"
                variant="link"
                onClick={() => {
                  window.open('https://www.facebook.com/RobiFanz', '_blank');
                }}
              >
                Visit Facebook Page
              </Button>
            </li>
            <li>
              <Button
                type="button"
                variant="link"
                onClick={() => {
                  window.open('https://www.instagram.com/robiaxiata', '_blank');
                }}
              >
                Visit Instagram
              </Button>
            </li>
          </ul>
        </MenuItems>
      </DropdownWrapper>
      <EndChatModal onConfirm={handleEndChatConfirm} isOpen={isEndChatModal} onClose={handleEndChatModalClose} />
    </Fragment>
  );
});
