import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.03);
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};

  .Dialog {
    width: 336px;
    min-height: 124px;
    padding: 12px 16px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.border.radius};
    box-shadow: -3px 21px 100px rgba(0, 0, 0, 0.09);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-29px);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s ease;

    ${({ isActive }) =>
      isActive &&
      css`
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      `}
  }
`;

export const ModalHeading = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const ModalText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ModalButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  button {
    margin-right: 24px;
    font-weight: 600;

    &:last-child {
      margin-right: 0;
    }
  }
`;
